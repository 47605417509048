/* eslint-disable guard-for-in */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import momentBusinessDays from 'moment-business-days';
import moment, { Moment } from 'moment';
import { Observable, Subject } from 'rxjs';
import { isEmpty, isEqual, pick } from 'lodash';
import { SPECIAL_NOTE_MAX_LENGTH } from '../app.const';

export const formatName = (name: string): string => {
  name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  name = name.replace(/-[a-z]/g, (match) => match.toUpperCase());
  return name;
};

export const camelCaseToRegularString = (camelCaseString: string): string => {
  // Use regular expression to insert a space before each capital letter
  const stringWithSpaces = camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Capitalize the first letter of each word
  return stringWithSpaces
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatFullName = (name: string): string => {
  name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  name = name.replace(/(-[a-z])|(\s\w)/g, (match) => match.toUpperCase());
  return name;
};

export const getAllFormErrors = (form: FormGroup | FormArray): { [key: string]: any } | null => {
  let hasError = false;
  const result = Object.keys(form.controls).reduce((acc, key) => {
    const control = form.get(key);
    const errors =
      control instanceof FormGroup || control instanceof FormArray
        ? getAllFormErrors(control)
        : control.errors;
    if (errors) {
      acc[key] = errors;
      hasError = true;
    }
    return acc;
  }, {} as { [key: string]: any });
  return hasError ? result : null;
};

export const markAllFormErrors = (form: FormGroup | FormArray): void => {
  Object.keys(form.controls).forEach((key) => {
    const control = form.get(key);
    if (control instanceof FormGroup || control instanceof FormArray) {
      markAllFormErrors(control);
    }

    markFormControlError(control);
  });
};

export const markFormControlError = (control: FormControl | AbstractControl): void => {
  if (!control.touched || !control.dirty) {
    if (!control.touched) {
      control.markAsTouched({ onlySelf: true });
    }

    if (!control.dirty) {
      control.markAsDirty({ onlySelf: true });
    }

    control.patchValue(control.value, { onlySelf: true });
  }
};

export const deepEqual = (x, y) => {
  const ok = Object.keys;
  const tx = typeof x;
  const ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
};

export const getFilenameFromHttpResponse = (response) =>
  response.headers['Content-Disposition'][0]
    .split(';')[1]
    .split('filename')[1]
    .split('=')[1]
    .trim();

export const getCookie = (key: string) => {
  const name = `${key}=`;
  const cookieArray = document.cookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookiePart = cookieArray[i];

    while (cookiePart.charAt(0) == ' ') {
      cookiePart = cookiePart.substring(1);
    }

    if (cookiePart.indexOf(name) == 0) {
      return cookiePart.substring(name.length, cookiePart.length);
    }
  }

  return '';
};

export const prepareSpecialNote = (note: string): CustomSpecialNote => {
  const specialNote: string = (note || '').trim().replace(/\s\s+/g, ' ');
  let specialShortNote: string = specialNote;
  let exceedsLimit = false;

  if (specialNote.length > SPECIAL_NOTE_MAX_LENGTH) {
    exceedsLimit = true;
    specialShortNote = `${specialNote.substring(0, SPECIAL_NOTE_MAX_LENGTH)} ...`;
  }

  return { note: specialNote, shortNote: specialShortNote, exceedsLimit };
};

const capitalizeWord = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
};

const capitalizeWords = (str: string) => {
  const words = str.split(' ');
  const newWords = [];

  words.forEach((word) => {
    if (word.length) {
      newWords.push(capitalizeWord(word));
    } else {
      newWords.push(word);
    }
  });

  return newWords.join(' ');
};

export const firstLetterUpperCase: ValidatorFn = (control: FormControl) => {
  const val: string = control.value;

  if (val && val.length) {
    const firstLetter = val.charAt(0);

    if (
      firstLetter !== firstLetter.toUpperCase() ||
      val.substring(1) !== val.substring(1).toLowerCase()
    ) {
      control.setValue(firstLetter.toUpperCase() + val.substring(1).toLowerCase(), {
        emitEvent: false,
      });
    }
  }

  return null;
};

export const capitalizeAllWords: ValidatorFn = (control: FormControl) => {
  const val: string = control.value;

  if (val && val.length) {
    const capitalizedWords = capitalizeWords(val);

    if (capitalizedWords !== val) {
      control.setValue(capitalizedWords, {
        emitEvent: false,
      });
    }
  }

  return null;
};

export const stripSpacesFromValue: ValidatorFn = (control: FormControl) => {
  const val: string = control.value;

  if (!val) {
    return null;
  }

  const withoutSpaces = val.replaceAll(' ', '');

  if (val !== withoutSpaces) {
    control.setValue(withoutSpaces);
  }

  return null;
};

export const getInternalDueDate = (workingDays, dueDateExternal) => {
  if (workingDays == 0) {
    return dueDateExternal;
  }

  if (workingDays >= 8) {
    // dueDateExternal day on Saturday -> internalDueDate day on Thursday
    if (dueDateExternal.day() === 6) {
      return dueDateExternal.subtract(2, 'days');
    }

    // dueDateExternal day on Sunday -> internalDueDate day on Thursday
    if (dueDateExternal.day() === 0) {
      return dueDateExternal.subtract(3, 'days');
    }

    // dueDateExternal day on Monday -> internalDueDate day on Thursday
    if (dueDateExternal.day() === 1) {
      return dueDateExternal.subtract(4, 'days');
    }

    // dueDateExternal day on Tuesday -> internalDueDate day on Friday
    if (dueDateExternal.day() === 2) {
      return dueDateExternal.subtract(4, 'days');
    }

    return dueDateExternal.subtract(2, 'days');
  }
  // dueDateExternal day on Sunday -> internalDueDate day on Friday
  if (dueDateExternal.day() === 0) {
    return dueDateExternal.subtract(2, 'days');
  }

  // dueDateExternal day on Monday -> internalDueDate day on Friday
  if (dueDateExternal.day() === 1) {
    return dueDateExternal.subtract(3, 'days');
  }

  return dueDateExternal.subtract(1, 'days');
};

export const countWorkingDays = (startDate: Moment, endDate: Moment) => {
  return momentBusinessDays(endDate).businessDiff(startDate);
};

export const getBlobFromJson = (obj: any): Blob => {
  return new Blob([JSON.stringify(obj)], { type: 'application/json' });
};

export const formatedStringToNumber = (str: string) => {
  if (typeof str !== 'string') {
    return str;
  }

  return Number(str.replaceAll(/\$|,|%/g, ''));
};

export const removeDuplicateObjects = (arr: any[]) => {
  const seen: Record<string, boolean> = {};

  return arr.filter((obj) => {
    const key = obj.key + obj.value; // Concatenate values that define uniqueness

    if (seen[key]) {
      return false;
    }

    seen[key] = true;
    return true;
  });
};

export const fileToBase64 = (file: File) => {
  return new Observable<string>((subscriber) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      subscriber.next(reader.result as string);
      subscriber.complete();
    };
    reader.onerror = (err) => {
      subscriber.error(err);
    };
  });
};

export const getFormErrors = (form: FormGroup | FormArray, errors: any[] = []): string[] => {
  for (const controlName in form.controls) {
    const control = form.controls[controlName] as AbstractControl;

    if (control.disabled) {
      continue;
    }

    if (control instanceof FormGroup || control instanceof FormArray) {
      getFormErrors(control, errors);
      continue;
    }

    const controlErrors = control.errors || {};

    // Collect custom error messages as objects with control name as the key
    if (controlErrors['custom']) {
      errors.push({ [controlName]: controlErrors['custom'].message });
    }

    // Collect standard error keys as strings
    errors.push(...Object.keys(controlErrors).filter(key => key !== 'custom'));
  }

  return errors;
};

export const removeControlError = (control: FormControl, errName: string) => {
  if (!control.hasError(errName)) {
    return;
  }

  const controlErrors = control.errors;
  delete controlErrors[errName];

  control.setErrors(isEmpty(controlErrors) ? null : controlErrors);
};

// date example: 2023-02-15
export const dateParamToFormValue = (date: string) => {
  return date ? `${date}T00:00:00.000Z` : '';
};

export const yearOptions = (start: number, end: number) => {
  const years = [];
  const dateNow = moment();

  for (let i = start; i <= end; i++) {
    const year = (dateNow.year() + i).toString();
    years.push({ year });
  }

  return years.reverse();
};

export const getConditionsFromTo = (prev: any, curr: any, props: string[]) => {
  const [fromMonth, fromYear, toMonth, toYear] = props;

  const condition1 =
    ((curr[fromMonth] && curr[fromYear]) || (prev[fromMonth] && prev[fromYear])) &&
    !isEqual(pick({ ...prev }, [fromMonth, fromYear]), pick({ ...curr }, [fromMonth, fromYear]));
  const condition2 =
    ((curr[toMonth] && curr[toYear]) || (prev[toMonth] && prev[toYear])) &&
    !isEqual(pick({ ...prev }, [toMonth, toYear]), pick({ ...curr }, [toMonth, toYear]));

  return [!!condition1, !!condition2];
};

export const updateControlsValueAndValidity = (form: FormGroup | FormArray) => {
  for (const controlName in form.controls) {
    const control = form.controls[controlName] as AbstractControl;

    if (control.disabled) {
      continue;
    }

    if (control instanceof FormGroup || control instanceof FormArray) {
      updateControlsValueAndValidity(control);
      continue;
    }

    control.markAsDirty();
    control.markAsTouched();

    if ((control as any).updateErrors$) {
      (control as any).updateErrors$.next(new Date());
    }
  }
};

export const addToControlsUpdateErrorsSubject = (form: FormGroup | FormArray) => {
  for (const controlName in form.controls) {
    const control = form.controls[controlName] as AbstractControl;

    if (control instanceof FormGroup || control instanceof FormArray) {
      addToControlsUpdateErrorsSubject(control);
      continue;
    }

    (control as any).updateErrors$ = new Subject<any>();
  }
};

export const stringToSSNFormat = (str: string) => {
  return str ? `${str.substring(0, 3)}-${str.substring(3, 5)}-${str.substring(5)}` : '';
};

export interface CustomSpecialNote {
  note: string;
  shortNote: string;
  exceedsLimit: boolean;
}

export const arrayAreAllEqualValue = (arr: Array<any>, value: any) => {
  if (arr.length === 0) {
    return false;
  }

  for (const element of arr) {
    if (element !== value) {
      return false; // If any element is not equal to the specified value, return false
    }
  }

  return true;
};

export const capitalizeFirstLetter = (val: string) => {
  return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
};
