import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectIs } from 'src/app/models/projects.model';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { UserIs } from 'src/app/models/user-is.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  projectAbbreviation = environment.project.abbreviation;

  accessRules = {
    components: {
      header: {
        show: {
          section: {
            online_users: [ProjectIs.UTMC],
            release_info: [ProjectIs.UTMC],
          },
          logo: {
            miia: [ProjectIs.UTMC],
          },
        },
      },
      support_modal: {
        show: {
          section: {
            contact_us: [ProjectIs.UTMC],
            help_center: {
              [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.CLIENT],
              [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL, UserIs.CLIENT],
              [ProjectIs.ACME]: [UserIs.INTERNAL, UserIs.CLIENT],
            },
          },
        },
      },
    },
    common: {
      register: {
        show: {
          registration_video_tutorial_link: [ProjectIs.UTMC],
        },
      },
      login: {
        show: {
          first_login_modal: {
            [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.CLIENT],
            [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL, UserIs.CLIENT],
            [ProjectIs.ACME]: [UserIs.INTERNAL, UserIs.CLIENT],
          },
        },
      },
    },
    cms: {
      case: {
        list: {
          show: {
            filter: {
              partner_program: {
                [ProjectIs.UTMC]: [UserIs.INTERNAL, UserIs.PARTNER],
                [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL],
                [ProjectIs.ACME]: [UserIs.INTERNAL],
              },
            },
          },
        },
      },
      sides: {
        view: {
          show: {
            tabs: {
              jobs: {
                mpc: [ProjectIs.UTMC],
                dd: [ProjectIs.UTMC],
                adf: [ProjectIs.UTMC],
                bc: [ProjectIs.UTMC],
              },
            },
          },
        },
        list: {
          show: {
            element: {
              button: {
                header: {
                  create: [ProjectIs.UTMC],
                },
                dev_button: {
                  mpc_request: [ProjectIs.UTMC],
                  mpc_response: [ProjectIs.UTMC],
                  dd_request: [ProjectIs.UTMC],
                  dd_response: [ProjectIs.UTMC],
                  adf_request: [ProjectIs.UTMC],
                  adf_response: [ProjectIs.UTMC],
                  bc_request: [ProjectIs.UTMC],
                  bc_response: [ProjectIs.UTMC],
                },
              },
            },
          },
        },
      },
      benefit_charge: {
        list: {
          show: {
            element: {
              button: {
                header: {
                  reports: [ProjectIs.UTMC],
                  upload_csvs: [ProjectIs.UTMC],
                },
              },
            },
            filter: {
              partner_program: {
                [ProjectIs.UTMC]: [],
                [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL],
                [ProjectIs.ACME]: [UserIs.INTERNAL],
              },
            },
          },
        },
      },
      benefit_charges_report: {
        common: {
          show: {
            element: {
              header: {
                export_to_excel: [ProjectIs.UTMC],
              },
            },
          },
        },
      },
      help: {
        common: {
          show: {
            section: {
              video_tutorials: [ProjectIs.UTMC],
            },
          },
        },
      },
    },
    cm: {
      clients: {
        export: {
          show: {
            filter: {
              billable: [ProjectIs.UTMC],
              problem: [ProjectIs.UTMC],
              partner_program: {
                [ProjectIs.UTMC]: [],
                [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL],
                [ProjectIs.ACME]: [UserIs.INTERNAL],
              },
              partner_tags: [ProjectIs.UTMC],
            },
          },
        },
        common: {
          show: {
            field: {
              reference: [ProjectIs.UTMC],
              auto_protest_charge: [ProjectIs.UTMC],
              partner_program: {
                [ProjectIs.UTMC]: [],
                [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL],
                [ProjectIs.ACME]: [UserIs.INTERNAL],
              },
            },
            section: {
              problem_account: [ProjectIs.UTMC],
            },
            tab: {
              contract: [ProjectIs.UTMC],
              addresses: [ProjectIs.UTMC],
              introduction_letter: [ProjectIs.UTMC],
              account_history: [ProjectIs.UTMC],
            },
          },
        },
        list: {
          show: {
            table_column: {
              contract_begins: [ProjectIs.UTMC],
              contract_ends: [ProjectIs.UTMC],
              billable: [ProjectIs.UTMC],
              problem: [ProjectIs.UTMC],
            },
            filter: {
              billable: [ProjectIs.UTMC],
              problem: [ProjectIs.UTMC],
              partner_program: {
                [ProjectIs.UTMC]: [],
                [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL],
                [ProjectIs.ACME]: [UserIs.INTERNAL],
              },
              partner_tags: [ProjectIs.UTMC],
            },
          },
        },
      },
      personnel: {
        edit: {
          show: {
            field: {
              rate_notices: [ProjectIs.UTMC],
            },
            section: {
              tags_help_tooltip: [ProjectIs.STOP_CLAIMS, ProjectIs.ACME],
            },
          },
        },
        view: {
          show: {
            field: {
              rate_notices: [ProjectIs.UTMC],
            },
          },
        },
      },
    },
  };

  constructor(private authorizationSvc: AuthorizationService) {}

  getModelByProject(model: any) {
    if (typeof model !== 'object') {
      return model;
    }

    return model[this.projectAbbreviation] || model;
  }

  /*

    example_1: [ProjectIs.UTMC],

    example_2: {
      [ProjectIs.UTMC]: [],
      [ProjectIs.STOP_CLAIMS]: [UserIs.INTERNAL],
      [ProjectIs.ACME]: [UserIs.INTERNAL],
    }

    example_3: {
      [ProjectIs.UTMC]: [
        {
          roles: [UserIs.INTERNAL.ANALYST],
          tags: []
        },
        {
          roles: [UserIs.CLIENT],
          tags: [UserTags.BILL, UserTags.BILL_],
        }
      ]
    }

   */
  validAccessRuleByProject(rule: ProjectIs[] | { [key in ProjectIs]?: any }): boolean {
    if (Array.isArray(rule)) {
      // Handle the case where the rule is an array of projects
      return rule.includes(this.projectAbbreviation);
    }
    // Handle the case where the rule is a map of projects to roles
    if (!(this.projectAbbreviation in rule)) {
      return false;
    }

    const projectRules = rule[this.projectAbbreviation];
    if (
      Array.isArray(projectRules) &&
      projectRules.length > 0 &&
      typeof projectRules[0] === 'object' &&
      projectRules[0].roles
    ) {
      // TOOD fix
      return this.authorizationSvc.userIsAndHasTag(projectRules);
    }

    // If roles array is empty, allow access for all roles
    if (projectRules.length === 0) {
      return true;
    }

    // Check if the user has one of the specified roles
    return this.authorizationSvc.userIs(projectRules);
  }
}
